import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/_lib/utils'
import { Attachment } from '@/_models/shared/attachment'
import { RotateCwIcon } from 'lucide-react'
import { toast } from 'sonner'
import { STLTooltip } from '../stl-tooltip'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-md font-medium ring-offset-background transition-colors focus-visible:outline-none outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'primary-button-gradient text-primary-foreground',
        destructive:
          'bg-danger text-danger-foreground hover:bg-danger/90 focus-visible:ring-danger !text-danger-foreground',
        outline:
          'border border-input bg-background hover:bg-accent-light/80 hover:text-accent-foreground focus-visible:ring-accent',
        secondary: 'secondary-button-gradient text-secondary-foreground',
        success:
          'bg-success text-success-foreground hover:bg-success/90 focus-visible:ring-success',
        ghost: 'hover:bg-grey-7',
        warning:
          'bg-warning !text-warning-foreground hover:bg-warning/90 focus-visible:ring-warning',
        link: 'text-secondary !p-0 underline-offset-4 hover:underline'
      },
      size: {
        default: 'h-10 px-6 py-4',
        sm: 'h-9 text-xs rounded-md px-3',
        md: 'h-12 text-base rounded-md px-4',
        lg: 'h-16 rounded-md px-8',
        icon: 'h-10 w-10'
      },
      full: {
        true: 'w-full'
      },
      capitalize: {
        true: 'capitalize',
        false: ''
      }
    },
    defaultVariants: {
      variant: 'default',
      capitalize: false,
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  loadingText?: string
  showLoadingText?: boolean
  tooltip?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      loading,
      size,
      capitalize,
      full = false,
      asChild = false,
      showLoadingText = true,
      loadingText = 'Please wait...',
      tooltip,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    if (variant === 'secondary') {
      return (
        <STLTooltip content={tooltip}>
          <div
            className={cn(
              'p-0.5 rounded-md secondary-button-border-gradient flex items-center justify-center',
              className,
              {
                'p-[1px]': size === 'sm',
                'w-full': full,
                'w-fit': !full
              }
            )}
          >
            <Comp
              className={cn(
                'm-0 p-0',
                buttonVariants({ full, capitalize, variant, size, className })
              )}
              ref={ref}
              {...props}
            />
          </div>
        </STLTooltip>
      )
    }
    if (loading) {
      return (
        <STLTooltip content={tooltip}>
          <Comp
            className={cn(buttonVariants({ full, variant, size, className }))}
            ref={ref}
            disabled
            {...props}
          >
            <RotateCwIcon
              className={cn('h-4 w-4 animate-spin', showLoadingText && 'mr-2')}
            />
            {showLoadingText && loadingText}
          </Comp>
        </STLTooltip>
      )
    }
    return (
      <STLTooltip content={tooltip}>
        <Comp
          className={cn(buttonVariants({ full, variant, size, className }))}
          ref={ref}
          {...props}
        />
      </STLTooltip>
    )
  }
)
Button.displayName = 'Button'

export interface DownloadButtonProps extends ButtonProps {
  fileUrl: string
  attachment?: Attachment
}

const DownloadButton = React.forwardRef<HTMLButtonElement, DownloadButtonProps>(
  ({ children, fileUrl, type = 'button', attachment, ...props }, ref) => {
    const [isLoading, setIsLoading] = React.useState(false)

    const handleDownload = async () => {
      try {
        setIsLoading(true)
        const downloadUrl = attachment != null ? attachment.url : fileUrl

        const response = await fetch(downloadUrl)
        if (!response.ok) throw new Error('Network response was not ok')

        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        a.download = attachment?.name ?? new Date().toJSON() // Extract filename from URL
        document.body.appendChild(a)
        a.click()

        // Clean up
        a.remove()
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error: any) {
        toast.error('Download failed:', {
          description: error!.message
        })
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <Button
        onClick={(event) => {
          props.onClick?.(event)
          handleDownload()
        }}
        type={type}
        ref={ref}
        loading={isLoading}
        disabled={isLoading}
        {...props}
      >
        {children}
      </Button>
    )
  }
)
DownloadButton.displayName = 'DownloadButton'

export { Button, buttonVariants, DownloadButton }
